<template>
  <div id="reservedSurgery">
    <!-- back -->
    <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("SurgeryTreatment") }}
      </p>
    </div>
    <div class="vx-row mt-4">
      <!-- search side -->
      <div class="vx-col lg:w-1/4 m-b-2 w-full mt-5">
        <div class="vx-row w-full">
          <p
            class="vx-col lg:w-1/2 p-2 w-full text-center"
            style="background-color: #eeeeee; border-radius: 10px"
          >
            {{ $t("From") }}<br />
            {{ search.DateFrom }}
          </p>
          <p
            class="vx-col lg:w-1/2 p-2 w-full text-center primaryBackColor"
            style="background-color: primary; color: white; border-radius: 10px"
          >
            {{ $t("To") }} <br />
            {{ search.DateTo }}
          </p>
        </div>
        <v-date-picker
          v-model="search.range"
          multiple="true"
          :locale="$vs.rtl ? 'ar' : 'en'"
          :attributes="attrs"
          is-range
          @change="changeDateFormat"
          @dayclick="changeDateFormat"
          class="w-full mt-2"
          ref="calendar"
          @update:from-page="pageChange"
        />
        <vs-card
          v-if="IsHospitalRole"
          class="vx-col lg:w-full mt-5 w-full mt-5"
          style="height: 30vh"
        >
          <vs-input
            v-model="search.SpecialityName"
            placeholder="search"
            class="w-full"
            name="search"
          />
          <div
            v-for="item in filterSpeciality"
            :key="item.ID"
            :class="item.IsSelected ? 'Active' : 'InActive'"
            class="shadow-md w-full mt-2"
          >
            <p class="m-5 p-3 vx-row">
              <vs-checkbox
                v-model="item.IsSelected"
                @input="SpecialityChecked(item)"
              ></vs-checkbox>
              {{ item.Specialty.Name }}
            </p>
          </div>
        </vs-card>
      </div>
      <div class="vx-col lg:w-3/4 mt-5 w-full">
        <h3
          v-if="IsHospitalRole"
          class="text-center mb-5 w-full"
          style="color: orange"
        >
          {{ $t("HospitalConfirmNote") }}
        </h3>
        <div class="vx-col lg:w-full sm:w-1/2 scroll">
          <table class="w-full">
            <tr class="HeaderTableColor" style="height: 50px">
              <th class="h-25 text-center">
                {{ $t("Surgery") }}
              </th>
              <th id="Patient" class="h-25 text-center">
                {{ $t("Patient") }}
              </th>

              <th id="Date" class="h-25 text-center">
                {{ $t("DateFrom") }}
              </th>
              <th id="Time" class="h-25 text-center">
                {{ $t("DateTo") }}
              </th>

              <th id="date" class="h-25 text-center">
                {{ $t("SurgeryDate") }}
              </th>
              <th v-if="IsHospitalRole" id="date" class="h-25 text-center">
                {{ $t("Doctor") }}
              </th>
              <th v-if="IsHospitalRole" id="date" class="h-25 text-center">
                {{ $t("Price") }}
              </th>
              <th id="status" class="h-25 text-center">
                {{ $t("Status") }}
              </th>
              <!-- <th
              id="paymentType"
              class="  h-25 text-center"
            >
              {{ $t("PaymentType") }}
            </th> -->
              <th id="paymentStatus" class="h-25 text-center">
                {{ $t("PaymentStatus") }}
              </th>
            </tr>
            <tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in reservedSurgeries"
              class="p-5"
            >
              <th>
                <div >
                  <u
                  @click="ViewSurgeryData(tr)"
                    v-if="
                      tr.HospitalSurgery &&
                      tr.HospitalSurgery.Surgery &&
                      (tr.StatusID == 1 || tr.StatusID == 2 || tr.StatusID == 7|| tr.StatusID == 8)
                    "
                    style="color: primary; cursor: pointer"
                    >{{ tr.HospitalSurgery.Surgery.Name }}</u
                  >
                  <p
                    v-if="
                      tr.HospitalSurgery &&
                      tr.HospitalSurgery.Surgery &&
                      tr.StatusID != 1 &&
                      tr.StatusID != 2 &&
                      tr.StatusID != 7 &&
                      tr.StatusID != 8
                    "
                  >
                    {{ tr.HospitalSurgery.Surgery.Name }}
                  </p>
                </div>
              </th>
              <td class="product-name font-medium truncate">
                <u
                  v-if="
                    tr.Patient &&
                    (tr.StatusID == 1 || tr.StatusID == 2 || tr.StatusID == 7|| tr.StatusID == 8)
                  "
                  @click="showPatientHistory(tr.PatientID)"
                >
                  {{ tr.Patient.Name }}</u
                >
                <p
                  v-if="
                    tr.Patient &&
                    tr.StatusID != 1 &&
                    tr.StatusID != 2 &&
                    tr.StatusID != 7 &&
                      tr.StatusID != 8
                  "
                >
                  {{ tr.Patient.Name }}
                </p>
              </td>

              <td>
                <p class="product-name font-medium truncate" type="date">
                  {{ tr.SurgeryDateFrom.split("T")[0] }}
                </p>
              </td>
              <td>
                <p class="product-name font-medium truncate">
                  {{ tr.SurgeryDateTo.split("T")[0] }}
                </p>
              </td>
<!-- SurgeryDate -->
              <td>
                <p v-if="tr.SurgeryDate">{{ tr.SurgeryDate.split("T")[0] }}</p>

                <u
                  @click="
                    ShowChangeStatus = true;
                    addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      PatientID: tr.PatientID,
                      dateFrom: tr.SurgeryDateFrom,
                      dateTo: tr.SurgeryDateTo,
                      viewType: 2,
                    };
                  "
                  v-if="(IsHospitalRole && tr.Status.Name != 'Completed') &&  tr.Status.Name != 'Cancelled'  && (tr.Status.Name != 'Confirmed') "
                  >{{ $t("SetDate") }}</u
                >
              </td>
<!-- Doctor -->
              <td v-if="IsHospitalRole">
                <span v-if="tr.Doctor">{{ tr.Doctor.Name }}</span>
                <span v-else></span>
                <br />
                 <u
                  @click="
                    ShowDoctor = true;
                    addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      DoctorID: tr.DoctorID,
                      viewType: 1,
                    };
                  "
                  v-if="(tr.Status &&  tr.Status.Name != 'Completed') && (tr.Status.Name != 'Cancelled')  && (tr.Status.Name != 'Confirmed')"
                >
                  {{ $t("Change") }}</u
                >
                <u
                  @click="
                    ShowDoctor = true;
                    addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      viewType: 1,
                    };
                  "
                  v-if="(tr.Status &&  tr.Status.Name != 'Completed') && (tr.Status.Name != 'Cancelled') && (tr.Status.Name != 'Confirmed')"
                  >{{ $t("Set") }}</u
                >
              </td>
<!-- Price   -->
              <td v-if="IsHospitalRole">
                <span v-if="tr.Price">{{ tr.Price + " USD" }}</span>
                <span v-else></span>
                <br />
                <u
                v-if="(tr.Status &&  tr.Status.Name != 'Completed') && (tr.Status.Name != 'Cancelled') && (tr.Status.Name != 'Confirmed')"
                  @click="
                    ShowDoctor = true;
                    addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      Price: tr.Price,
                      DoctorID: tr.DoctorID,
                      viewType: 2,
                    };
                  "
                >
                  {{ $t("Change") }}</u
                >
              </td>

              <!-- Status -->
              <td>
                <!-- <p
                v-if="tr.Status && tr.Status.Name == 'Cancelled'">
                  {{ tr.Status.Name }}
                </p> -->
                <p
                v-if="(tr.Status &&  tr.Status.Name == 'Cancelled')  || (tr.Status.Name == 'Completed')">
                  {{ tr.Status.Name }}
                </p>
                <u
                  style="cursor: pointer"
                  @click="
                    ShowChangeStatus = true;
                    addItemModel = {
                      viewType: 1,
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      PatientID: tr.PatientID,
                      CurrentStatusID: tr.StatusID,
                      PaymentStatusID: tr.PaymentStatusID
                    };
                  "
                  v-else-if="tr.Status"
                  >{{ tr.Status.Name }}</u
                >
              </td>
              <!-- <td>
              <p v-if="tr.PaymentType">{{tr.PaymentType.Name}}</p>
            </td> -->
              <!-- PaymentStatus -->
              <td>
                <!-- payment type must be hospital bank transfer to can change status -->
                <p v-if="(tr.PaymentTypeID != 5  && tr.PaymentStatus != null) ||  tr.StatusID==3">
                  {{ PaymentStatus(tr) }}
                </p>
                <u
                  style="cursor: pointer"
                  @click="
                    ShowChangeStatus = true;
                    addItemModel = {
                      viewType: 3,
                      ID: tr.ID,
                      StatusID: tr.StatusID,
                      SurgeryDateFrom: tr.SurgeryDateFrom,
                      SurgeryDateTo: tr.SurgeryDateTo,
                      PaymentStatusID: tr.PaymentStatusID,
                      CurrentStatusID: tr.PaymentStatusID,
                    };
                  "
                  v-else-if="tr.PaymentStatus != null"
                  >{{ PaymentStatus(tr) }}</u
                >
              </td>
            </tr>
            <tr v-if="reservedSurgeries.length == 0">
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td>
                <h3 class="w-full sm:w-auto text-center text-primary mt-5 mb-5">
                  {{ $t("NoDataToshow") }}
                </h3>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr></tr>
          </table>
        </div>
      </div>
    </div>
    <vs-popup title="" :active.sync="ShowChangeStatus">
      <SurgerySetPopup
        :itemModel="addItemModel"
        v-if="ShowChangeStatus"
        @closePop="
          ShowChangeStatus = false;
          searchReservedSurgery();
        "
        @SaveChange="
          SaveChange();

        "
      ></SurgerySetPopup>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowDoctor">
      <SurgerySetDoctorPopup
        :itemModel="addItemModel"
        v-if="ShowDoctor"
        @closePop="
          ShowDoctor = false;
          searchReservedSurgery();
        "
        @SaveChange="
          setDoctorAndPriceSurgery();
        "
      ></SurgerySetDoctorPopup>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowSuccessPopup">
      <SuccessPopup
        :Message="$t('SuccsessMessage')"
        @CloseSeccussPopup="CloseSeccussPopup"
        v-if="ShowSuccessPopup"
      ></SuccessPopup>
    </vs-popup>
  </div>
</template>

<script>
import moment from "moment";
import HospitalModule from "@/store/hospital/moduleHospital.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleStatus from "@/store/settings/status/moduleStatus.js";
import SurgerySetPopup from "./SurgerySetPopup.vue";
import SurgerySetDoctorPopup from "./SurgerySetDoctorPopup.vue";
import SuccessPopup from "@/views/pages/register/SuccessPopup.vue";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";

export default {
  components: {
    SurgerySetPopup,
    SurgerySetDoctorPopup,
    SuccessPopup,
  },
  data() {
    return {
      EnglishLan: false,
      IsHospitalRole: false,
      ShowSuccessPopup: false,
      addItemModel: {},
      ShowChangeStatus: false,
      ShowDoctor: false,
      hospitalSpecialty: [],

      format: "MM/DD/yyyy",
    };
  },
  computed: {
      search() {
      return this.$store.state.HospitalList.search;
    },
    attrs() {
       debugger
    var sugeries= [
        {
          bar: "green",
          dates:
            this.$store.state.patientList.MonthPatientReservationSurgeries.map(
              (ob) =>  ob.SurgeryDateFrom
            ),
        },
      ];

      var surgeriesDate=  this.$store.state.patientList.MonthPatientReservationSurgeries.filter(obj=> obj.SurgeryDate).map(
              (ob) =>  ob.SurgeryDate
        );
    if(surgeriesDate&&surgeriesDate.length>0)
        sugeries[0].dates.push(...surgeriesDate)
      return sugeries;
    },
    status() {
      return this.$store.state.StatusList.status;
    },
    reservedSurgeries() {
      return this.$store.state.patientList.PatientReservationSurgeries;
    },
    filterSpeciality() {
      if (this.search.SpecialityName) {
        var vm = this;
        return this.hospitalSpecialty.filter((obj) =>
          obj.Specialty.Name.toLowerCase().includes(
            vm.search.SpecialityName.toLowerCase()
          )
        );
      } else return this.hospitalSpecialty;
    },
  },
  methods: {
    PaymentStatus(tr) {
      debugger;
      if (tr.PaymentStatus.ID == 7)
        return !this.$vs.rtl ? "Pending" : "قيد الانتظار";
      else return tr.PaymentStatus.Name;
    },
    CloseSeccussPopup() {
      this.ShowSuccessPopup = false;
    },
    pageChange(obj) {
      debugger;
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        DateTo:
          obj.year +
          "/" +
          obj.month +
          "/" +
          new Date(obj.year, obj.month, 0).getDate(),
      };
      if (this.IsHospitalRole)
        search.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;

     if (this.$acl.check('hospitalgroup'))
          search.HospitalGroupID = this.$store.state.AppActiveUser.HospitalGroupID;

      else search.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;

      search.NotIncludePaymentStatusIds = [1];
      this.$store.dispatch(
        "patientList/getPatientReservationSurgeryCalender",
        search
      );
    },

    setDoctorAndPriceSurgery() {
      debugger
      this.$vs.loading();
      if (this.addItemModel.viewType == 1) {
        this.$store
          .dispatch(
            "HospitalList/SetDoctorPatientReservationSurgery",
            this.addItemModel
          )
          .then((res) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.ShowDoctor = false;
          this.ShowSuccessPopup = true;
            this.searchReservedSurgery();
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            this.ShowDoctor = false;
          });
      } else {
        debugger;
        this.$store
          .dispatch(
            "HospitalList/ChangePatientReservationSurgeryPice",
            this.addItemModel
          )
          .then((res) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.ShowDoctor = false;
             this.ShowSuccessPopup = true;
            this.searchReservedSurgery();
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            this.ShowDoctor = false;
          });
      }
    },
    SaveChange() {
      debugger
      this.$vs.loading();
        if (this.addItemModel.viewType == 1) {
          this.$store
            .dispatch(
              "HospitalList/ChangePatientReservationSurgeryStatus",
              this.addItemModel
            )
            .then((res) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("success"),
                text: res.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.ShowChangeStatus = false;
              this.ShowSuccessPopup = true;
              this.searchReservedSurgery();
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("Error"),
                text: err.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });

            });
        } else if (this.addItemModel.viewType == 3) {
          this.$store
            .dispatch(
              "HospitalList/UpdatePatientReservationSurgery",
              this.addItemModel
            )
            .then((res) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("success"),
                text: res.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.ShowChangeStatus = false;
              this.ShowSuccessPopup = true;
              this.searchReservedSurgery();
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("Error"),
                text: err.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });

            });
        } else {
          this.$store
            .dispatch(
              "HospitalList/ConfirmPatientReservationSurgeryDate",
              this.addItemModel
            )
            .then((res) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("success"),
                text: res.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.ShowChangeStatus = false;
              this.ShowSuccessPopup = true;
              this.searchReservedSurgery();
            })
            .catch((err) => {
              debugger;
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("Error"),
                text: err.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });

            });
        }
    },
    SpecialityChecked(surgery) {
      this.searchReservedSurgery();
    },
    searchReservedSurgery() {
      if (this.IsHospitalRole||this.$acl.check('hospitalgroup')) {
        this.search.SpecialtyIds = this.hospitalSpecialty
          .filter((obj) => obj.IsSelected)
          .map((b) => b.Specialty.ID);
      }
      this.search.NotIncludePaymentStatusIds = [1];

      this.$store.dispatch(
        "patientList/getPatientReservationSurgery",
        this.search
      );
    },
    showPatientHistory(PatientID) {
      debugger;
      this.$router.push({
        name: "PatientMedicalHistorySession",
        params: { ID: PatientID },
      });
    },

    changeDateFormat() {
      debugger;
      this.search.DateFrom = moment(this.search.range.start).format(
        this.format
      );
      this.search.DateTo = moment(this.search.range.end).format(this.format);

      this.searchReservedSurgery();
    },
    ViewSurgeryData(tr) {
      debugger
      if (tr.StatusID == 1 || tr.StatusID == 2 || tr.StatusID == 7||tr.StatusID==8)
        this.$router.push(
          "/TreatmentsProfile/" + tr.HospitalSurgeryID + "/" + 0 + "/" + tr.ID
        );
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;
    this.IsHospitalRole = this.$acl.check("hospital");
    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!moduleStatus.isRegistered) {
      this.$store.registerModule("StatusList", moduleStatus);
      moduleStatus.isRegistered = true;
    }

    this.$store.dispatch("StatusList/GetAllStatus");

    debugger;
    if(!this.search.range.start)
    {
        this.search.range.start = new Date();
        this.search.range.end = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 10
        );
    }
    if (this.IsHospitalRole)
      this.search.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;

     if (this.$acl.check('hospitalgroup'))
       this.search.HospitalGroupID = this.$store.state.AppActiveUser.HospitalGroupID;

    else
       this.search.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
    this.changeDateFormat();
  },
};
</script>
<style >
#reservedSurgery table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  background: white;
}
.scroll {
  overflow-x: auto !important;
}
#reservedSurgery th,
#reservedSurgery td {
  border: 1px solid #85828220;
  border-collapse: collapse;
  text-align: center;

  height: 50px;
}
#reservedSurgery .vx-row {
  margin: unset;
}
</style>
